import de from '~/locales/de.json'
import en from '~/locales/en.json'
import es from '~/locales/es.json'
import fr from '~/locales/fr.json'
import it from '~/locales/it.json'
import pt from '~/locales/pt.json'

export default {
  fallbackLocale: 'en',
  legacy: false,
  locale: 'en',
  messages: {
    de,
    en,
    es,
    fr,
    it,
    pt,
  },
}
